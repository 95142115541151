import { Layout } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Layout)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background: #000321;
  padding-bottom: 80px;
  min-height: 100svh;
`;
