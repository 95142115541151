import React, { memo } from 'react';
import { Wrapper } from './menu.styled';
import { useNavigate } from 'react-router';
import ArrowRight from '@/assets/arrow-right.png';
import { toast } from 'react-toastify';
import { addressSplit } from '@/utilities/string.utils';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import withConnect from '@/hoc/with-connect';
const menuList = [
  {
    navigate: '/',

    title: 'IDO listing',
    active: true
  },
  {
    navigate: '/claim',

    title: 'Claim',
    active: true
  },
  {
    navigate: '/profile',

    title: 'Profile',
    active: true
  }
];

const MenuComponent = ({ account, turnOff }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientHeight = window.innerHeight;
  return (
    <Wrapper>
      <div className='w-full h-full app-container flex flex-col items-center justify-start pt-[64px] px-4'>
        <div className='text-ink text-[40px] font-bold text-center primary-font'>Menu</div>
        <div className={`w-full flex flex-col gap-6 mt-6 ${clientHeight < 600 && '!gap-2'}`}>
          {menuList.map((menu, key) => {
            return (
              <div
                key={key}
                className='w-full flex items-center justify-between py-1 cursor-pointer'
                onClick={() => {
                  if (!menu.active) {
                    toast.dismiss();
                    toast.info('Coming soon!');
                    return;
                  }
                  navigate(menu.navigate);
                  turnOff();
                }}
              >
                <span className='primary-font font-bold text-[24px] text-appGreen'>{menu.title}</span>
                <img src={ArrowRight} className='w-6' alt='' />
              </div>
            );
          })}
          <div className='w-full h-[1px] bg-[#DDEBF1]'></div>
          {account ? (
            <div className='w-full border border-[#FFFFFF] p-4 rounded-3xl flex flex-col items-start'>
              <span className='primary-font text-sm font-medium text-[#5B7985]'>Wallet address</span>
              <div className='w-full flex items-center gap-2 mt-2'>
                <span className='primary-font text-sm font-bold text-ink'>{addressSplit(account)}</span>
              </div>
            </div>
          ) : (
            <div className='w-full border border-[#FFFFFF] p-4 rounded-3xl'>
              <div className='w-full'>
                <Button
                  className='w-full primary-button'
                  onClick={async () => {
                    toast.dismiss();
                  }}
                >
                  Connect
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export const Menu = memo(withConnect(MenuComponent));
export default Menu;
