import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Wrapper } from './profile.styled';
import Header from '@/components/header/header';
import Avatar from '@/assets/avatar.png';
import { ConnectWallet, useAddress } from '@thirdweb-dev/react';
import withConnect from '@/hoc/with-connect';
import { Button } from 'antd';
import { dispatch } from '@/app/store';
import { deleteAccount } from '@/redux';
import { useDisconnect } from '@thirdweb-dev/react';
import { addressSplit } from '@/utilities/string.utils';
import { LaunchPadRepository } from '@/repository/launchpad.repository';
import { useQuery } from '@tanstack/react-query';
import { lowerCacheOptions } from '@/constants/app.constant';
import Logo from '@/assets/home/Logo.png';
import SaleImg from '@/assets/sale.png';
import { ethers } from 'ethers';
const ProfilePageComponent = ({ token, account, onSigninWallet }: any) => {
  const disconnect = useDisconnect();
  const address = useAddress();
  const [userBuyActivities, setUserBuyActivities] = useState<any>([]);
  const { data: allLaunchpad } = useQuery({
    queryKey: ['retrieveAllLaunchpad', token],
    queryFn: () => LaunchPadRepository.getAllLaunchpad(),
    retry: false,
    enabled: !!token,
    ...lowerCacheOptions,
    refetchOnMount: 'always',
    refetchInterval: 60000
  });
  const currentLaunchPad = useMemo(() => {
    return allLaunchpad?.[0];
  }, [allLaunchpad]);

  // const userAccount = useMemo(() => {
  //   return connectedAccount || address || '';
  // }, [connectedAccount, address]);

  console.log('currentLaunchPad', currentLaunchPad);
  // const { data: allVestingClaim } = useQuery({
  //   queryKey: ['retrieveAllVestingClaim', userAccount, currentLaunchPad?.address],
  //   queryFn: () => LaunchPadRepository.getVestingClaim(userAccount, currentLaunchPad?.address),
  //   retry: false,
  //   enabled: !!currentLaunchPad?.address && !!userAccount,
  //   ...lowerCacheOptions,
  //   refetchOnMount: 'always',
  //   refetchInterval: 60000
  // });

  // const { data: estimateVestingClaim } = useQuery({
  //   queryKey: ['retrieveEstimateVestingClaim', userAccount, currentLaunchPad?.address],
  //   queryFn: () =>
  //     LaunchPadRepository.estimateNextVestingClaim(connectedAccount || address || '', currentLaunchPad?.address),
  //   retry: false,
  //   enabled: !!currentLaunchPad?.address && !!userAccount,
  //   ...lowerCacheOptions,
  //   refetchOnMount: 'always',
  //   refetchInterval: 60000
  // });

  // const { handleClaimVesting, isLoading } = useClaimVesting({
  //   launchpad: currentLaunchPad,
  //   onSuccess: () => {},
  //   onFailed: () => {}
  // });

  // console.log('allVestingClaim', allVestingClaim, estimateVestingClaim);

  // const {
  //   timerDays: endDays,
  //   timerHours: endHours,
  //   timerMinutes: endMinutes,
  //   timerSeconds: endSeconds,
  //   countdownDone: isEnd
  // } = useCountDown(new Date(estimateVestingClaim?.next_claim_time).getTime() || 0, '3');
  const calcUserBuyActivities = useCallback(async () => {
    await Promise.all(
      allLaunchpad?.map(async (item: any): Promise<any> => {
        if (!item) return;
        const res = await LaunchPadRepository.getUserBuyActivities(item?.address);

        if (res?.length > 0) {
          console.log('res', res);
          const _userBuyActivities = [...userBuyActivities];
          console.log('_userBuyActivities', _userBuyActivities);
          _userBuyActivities.concat(res);
          console.log('_userBuyActivities', _userBuyActivities);
          setUserBuyActivities(res);
        }
      })
    );
  }, [allLaunchpad, token]);
  useEffect(() => {
    calcUserBuyActivities();
  }, [calcUserBuyActivities]);

  console.log('userBuyActivities', userBuyActivities);
  // const { data: userBuyActivities } = useQuery({
  //   queryKey: ['retrieveUserBuyActivities', currentLaunchPad?.address],
  //   queryFn: () => LaunchPadRepository.getUserBuyActivities(currentLaunchPad?.address),
  //   retry: false,
  //   enabled: !!currentLaunchPad?.address,
  //   refetchInterval: 10000
  // });
  return (
    <Wrapper>
      <Header />
      <div className='relative z-[2] flex items-start gap-[64px] max-[990px]:gap-[36px] mt-[152px] w-full max-w-[1136px] px-4 max-mobile:flex-col'>
        <div className='flex flex-col items-start gap-4 flex-[0.3] max-mobile:w-full'>
          <div className='font-bold text-[40px] max-mobile:text-[32px]'>Profile</div>
          <div className='w-full bg-[#070A33] rounded-2xl flex flex-col items-center p-6'>
            <img src={Avatar} className='w-[120px]' alt='' />
            <div className='w-full h-[1px] bg-[#42467D] my-6'></div>
            {account && (
              <div className='w-full flex items-center justify-between'>
                <div className='font-bold text-sm '>Your wallet</div>
                <div
                  className='font-bold text-sm cursor-pointer text-[#31EAFF]'
                  onClick={() => {
                    dispatch(deleteAccount());
                    disconnect();
                  }}
                >
                  Disconnect
                </div>
              </div>
            )}
            <div className='w-full mt-4'>
              {!address ? (
                <Button className='primary-button relative'>
                  <div>
                    <span>Connect Wallet</span>
                    <ConnectWallet className='!opacity-0 !w-full  !h-full !absolute !top-0 !left-0'></ConnectWallet>
                  </div>
                </Button>
              ) : (
                <>
                  <div className='w-full border-[1px] border-[#FFFFFF] p-[10px] rounded-2xl flex items-center justify-center'>
                    <div className='font-normal text-xs text-[#948E9C] flex items-center justify-center text-center mt-[2px]'>
                      Address: {addressSplit(address || '...')}
                    </div>
                  </div>
                  {!token && (
                    <Button
                      className='primary-button relative mt-3'
                      onClick={() => {
                        if (!token && !!account) {
                          onSigninWallet();
                        }
                      }}
                    >
                      Sign
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className='flex flex-col items-start gap-4 flex-[0.7] max-mobile:w-full'>
          {/* <div className='font-bold text-[40px]  max-mobile:text-[32px]'>Vesting</div>

          <div className='w-full bg-[#070A33] rounded-2xl p-6 overflow-auto max-h-[800px]'>
            <div className='grid grid-cols-2 gap-y-8'>
              <div className='flex flex-col items-start gap-2'>
                <div className='text-sm text-[#948E9C] font-medium'>Total Token</div>
                <div className='flex items-center gap-2'>
                  <img src={Logo} className='w-5' alt='' />
                  <div className='text-sm font-bold'>
                    {' '}
                    {Number(ethers.utils.formatEther(estimateVestingClaim?.total_amount || '0'))?.toLocaleString('en', {
                      maximumFractionDigits: 5,
                      minimumFractionDigits: 0
                    })}{' '}
                    $HYPE
                  </div>
                </div>
              </div>
              <div className='flex flex-col items-start gap-2'>
                <div className='text-sm text-[#948E9C] font-medium'>Claimed Amount</div>
                <div className='flex items-center gap-2'>
                  <img src={Logo} className='w-5' alt='' />
                  <div className='text-sm font-bold'>
                    {Number(ethers.utils.formatEther(estimateVestingClaim?.claimed_amount || '0'))?.toLocaleString(
                      'en',
                      {
                        maximumFractionDigits: 5,
                        minimumFractionDigits: 0
                      }
                    )}{' '}
                    $HYPE
                  </div>
                </div>
              </div>
              <div className='flex flex-col items-start gap-2'>
                <div className='text-sm text-[#948E9C] font-medium'> Vesting Amount</div>
                <div className='flex items-center gap-2'>
                  <img src={Logo} className='w-5' alt='' />
                  <div className='text-sm font-bold'>
                    {' '}
                    {(
                      Number(ethers.utils.formatEther(estimateVestingClaim?.total_amount || '0')) -
                      Number(ethers.utils.formatEther(estimateVestingClaim?.claimed_amount || '0'))
                    )?.toLocaleString('en', {
                      maximumFractionDigits: 5,
                      minimumFractionDigits: 0
                    })}{' '}
                    $HYPE
                  </div>
                </div>
              </div>
              <div className='flex flex-col items-start gap-2'>
                <div className='text-sm text-[#948E9C] font-medium'>Vesting time</div>
                <div className='flex items-center gap-2'>
                  <div className='text-sm font-bold'>{estimateVestingClaim?.vesting_time} days</div>
                </div>
              </div>
            </div>
            <div className='vesting-section flex items-center gap-3 mt-6 p-4 bg-[#141960] rounded-2xl'>
              <div className='flex flex-col items-start flex-[0.45]'>
                <div className='font-bold text-base'>Claim Token </div>
                <div className='flex items-center gap-2 mt-3'>
                  <div className='w-[72px] h-[72px] rounded-2xl border-[1px] border-[#42467D] bg-[#10051C80] flex flex-col items-center justify-center'>
                    <span className='font-bold text-xs'>{endDays}</span>
                    <span className='font-normal text-xs'>days</span>
                  </div>
                  <div>:</div>
                  <div className='w-[72px] h-[72px] rounded-2xl border-[1px] border-[#42467D] bg-[#10051C80] flex flex-col items-center justify-center'>
                    <span className='font-bold text-xs'>{endHours}</span>
                    <span className='font-normal text-xs'>hours</span>
                  </div>
                  <div>:</div>
                  <div className='w-[72px] h-[72px] rounded-2xl border-[1px] border-[#42467D] bg-[#10051C80] flex flex-col items-center justify-center'>
                    <span className='font-bold text-xs'>{endMinutes}</span>
                    <span className='font-normal text-xs'>minutes</span>
                  </div>
                  <div>:</div>
                  <div className='w-[72px] h-[72px] rounded-2xl border-[1px] border-[#42467D] bg-[#10051C80] flex flex-col items-center justify-center'>
                    <span className='font-bold text-xs'>{endSeconds}</span>
                    <span className='font-normal text-xs'>seconds</span>
                  </div>
                </div>
              </div>
              <div className='flex-[0.1] flex items-center justify-center'>
                <div className='h-[80px] w-[1px] bg-[#42467D]'></div>
              </div>
              <div className='flex flex-col flex-[0.45]'>
                <div className='flex items-center gap-2'>
                  <div className='text-sm text-[#948E9C] font-medium'>Amount</div>
                  <img src={Logo} className='w-5' alt='' />
                  <div className='text-sm font-bold'>
                    {Number(ethers.utils.formatEther(estimateVestingClaim?.next_claim_amount || '0'))?.toLocaleString(
                      'en',
                      {
                        maximumFractionDigits: 5,
                        minimumFractionDigits: 0
                      }
                    )}{' '}
                    $HYPE
                  </div>
                </div>
                <div className='w-full mt-4'>
                  <Button
                    className='primary-button'
                    onClick={async () => {
                      if (!token && !!account) {
                        onSigninWallet();
                        return;
                      }
                      await handleClaimVesting();
                    }}
                    loading={isLoading}
                    disabled={!isEnd}
                  >
                    {account ? (
                      token ? (
                        'Claim'
                      ) : (
                        'Sign'
                      )
                    ) : (
                      <div>
                        <span>Connect Wallet</span>
                        <ConnectWallet className='!opacity-0 !w-full  !h-full !absolute !top-0 !left-0'></ConnectWallet>
                      </div>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div> */}
          <div className='font-bold text-[40px]  max-mobile:text-[32px]'>Investment history</div>
          <div className='w-full bg-[#070A33] rounded-2xl p-6 overflow-auto max-h-[800px]'>
            {userBuyActivities?.length > 0 ? (
              <div className=' flex flex-col items-center gap-4 max-mobile:min-w-[650px]'>
                <div className='w-full flex items-center gap-6'>
                  <div className='font-normal text-sm text-[#999999] flex-[0.25] text-start'>IDO Sales</div>
                  <div className='font-normal text-sm text-[#999999] flex-[0.25] text-center'>Funded</div>
                  <div className='font-normal text-sm text-[#999999] flex-[0.25] text-center'>Token claim</div>
                  <div className='font-normal text-sm text-[#999999] flex-[0.25] text-end'>Date</div>
                </div>
                <div className='w-full h-[1px] bg-[#42467D]'></div>
                {userBuyActivities?.map((item: any, key: number) => {
                  return (
                    <div key={key} className='w-full flex items-center gap-6 '>
                      <div className='flex items-center gap-2 flex-[0.25]'>
                        <img src={SaleImg} className='w-10' alt='' />
                        <div>
                          <div className='font-semibold text-sm'>HyperLaunch</div>
                          <div className='flex items-center gap-1'>
                            <img src={Logo} className='w-6' alt='' />
                            <div className='text-[#948E9C] font-semibold text-sm'>$HYPE</div>
                          </div>
                        </div>
                      </div>
                      <div className='font-normal text-sm flex-[0.25] text-center'>
                        $
                        {Number(ethers.utils.formatEther(item?.public_amount)) +
                          Number(ethers.utils.formatEther(item?.private_amount))}
                      </div>
                      <div className='flex-[0.25] flex items-center gap-2 justify-center'>
                        <div className='font-semibold text-sm'>... HYPE </div>
                        <img src={Logo} className='w-6' alt='' />
                      </div>
                      <div className='font-normal text-sm flex-[0.25] text-end'>
                        {new Date(item?.createdAt)?.toLocaleDateString('en-US')}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className='w-full px-2 py-4 text-center flex items-center justify-center font-bold text-sm'>
                NO HISTORY
              </div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export const ProfilePage = memo(withConnect(ProfilePageComponent));
