import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import { useChainId, useNetwork, useBalance, useAddress } from '@thirdweb-dev/react';
import { useCallback, useState } from 'react';
import { ENVS } from '@/config/env.config';
import { LaunchPadRepository } from '@/repository/launchpad.repository';
import LaunchpadABI from '@/constants/abi/launchpad.json';
import { useWriteTransaction } from '@/hooks/use-write-transaction';
interface IPropsUseClaimVesting {
  launchpad: any;
  onSuccess: () => void;
  onFailed: () => void;
}
export const useClaimVesting = (props: IPropsUseClaimVesting) => {
  const { data: accountBalance } = useBalance();
  const { launchpad, onSuccess } = props;
  console.log('launchpad', launchpad);
  const address = useAddress();
  const chainId = useChainId();
  const [, switchNetwork] = useNetwork();
  const [isLoading, setIsLoading] = useState(false);
  const { sendTransaction } = useWriteTransaction();

  const handleClaimVesting = useCallback(
    async (claimRaw?: any) => {
      try {
        setIsLoading(true);
        const baseChainId = parseInt(ENVS.VITE_BASE_CHAIN_ID?.[0] || '1');
        if (chainId !== baseChainId) {
          toast.dismiss();
          toast.error(`Network mismatched: ${chainId} -> ${baseChainId}`);
          switchNetwork && switchNetwork(baseChainId);
          setIsLoading(false);
          throw new Error('Network mismatched');
        }

        const claimData = {
          user_address: address,
          launchpad_address: launchpad?.address
        };
        console.log('hihih', claimData, launchpad?.address, address);
        let claimSignature: any;
        if (!claimRaw) {
          claimSignature = await LaunchPadRepository.getVestingClaimSignature(claimData);
        } else {
          claimSignature = claimRaw;
        }
        console.log('signature', claimSignature);
        if (accountBalance?.value?.lt(ethers.utils.parseEther('0.01'))) {
          toast.dismiss();
          setIsLoading(false);
          toast.error('Insufficient LUMIA amount for fees!');
          throw new Error('Insufficient LUMIA amount for fees!');
        } else {
          console.log('!!!!!!!!!!!!');
          const claimTuple = [
            claimSignature?.id,
            claimSignature?.buyer,
            BigInt(claimSignature?.pool_token_amount),
            BigInt(claimSignature?.sale_token_amount)
          ];
          console.log('claimTuple', claimTuple, claimSignature?.signature);
          const res = await sendTransaction(
            launchpad?.address,
            LaunchpadABI,
            'claim',
            '0',
            claimTuple,
            claimSignature?.signature
          );
          // const iface = new ethers.utils.Interface(LaunchpadABI);
          // const _data = [
          //   [
          //     claimSignature?.values?.queryId,
          //     claimSignature?.values?.buyer,
          //     ethers.utils.parseEther(claimSignature?.values?.publicAmount),
          //     BigInt(claimSignature?.values?.privateAmount)
          //   ],
          //   claimSignature?.signature
          // ];
          // console.log('_data', _data);
          // const data = iface.encodeFunctionData('buy', _data);
          // console.log('data', data);
          // const rawTransaction = {
          //   from: userWallet,
          //   to: '0x0770AcD6303aa0A3523a171B39F2a7Db7B88e583',
          //   value: 0,
          //   data: data,
          //   chainId: Number(ENVS.VITE_BASE_CHAIN_ID?.[0]),
          //   type: 0
          // };
          // console.log('rawTransaction', rawTransaction);
          // const tx = await sdk?.wallet?.sendRawTransaction(rawTransaction);
          // let receipt = await tx?.wait();
          // if (receipt) {
          //   onSuccess();
          // }
          console.log('res', res);
          if (res?.success) {
            toast.success('Claimed token successfully!');
          } else {
            toast.dismiss();
            toast.error(res?.data);
          }

          setIsLoading(false);
        }
      } catch (error: any) {
        setIsLoading(false);
        console.log('error', error);
        toast.dismiss();
        toast.error(error?.message || error || 'Something went wrong!');
      }
    },
    [accountBalance, chainId, switchNetwork, launchpad, sendTransaction, address]
  );
  return {
    handleClaimVesting,
    isLoading
  };
};
