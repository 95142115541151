import React, { memo, useEffect } from 'react';
import { Wrapper } from './header.styled';
import Logo from '@/assets/home/Logo.png';
import { Button } from 'antd';
import { useNavigate } from 'react-router';
import withConnect from '@/hoc/with-connect';
import { ConnectWallet } from '@thirdweb-dev/react';
import { addressSplit } from '@/utilities/string.utils';
import ArrowDown from '@/assets/CaretDown.png';
import { useOnOff } from '@/hooks/use-on-off';
import Menu from '../menu/menu';
import MenuIcon from '@/assets/menu.png';
import Close from '@/assets/close.png';
export const HeaderComponent = ({ account, token, onSigninWallet, isConnectedCompatibleChain }: any) => {
  const navigate = useNavigate();
  const { isOn: isMenu, turnOn: turnOnMenu, turnOff: turnOffMenu } = useOnOff();
  useEffect(() => {
    console.log('isConnectedCompatibleChain', isConnectedCompatibleChain);
    if (!isConnectedCompatibleChain) {
      console.log('isConnectedCompatibleChain', isConnectedCompatibleChain);
    }
  }, [isConnectedCompatibleChain]);
  // useEffect(() => {
  //   if (!account && !!token) {
  //     dispatch(deleteAccount());
  //   }
  // }, [account, token]);
  return (
    <>
      {isMenu && <Menu turnOff={turnOffMenu} />}
      <Wrapper
        className={`flex flex-col items-center overflow-visible w-full h-[80px] justify-center max-mobile-big:h-[64px] ${isMenu && '!fixed'}`}
      >
        <div className='w-full max-w-[1136px] flex items-center justify-between px-4'>
          <img
            src={Logo}
            className='w-[56px] max-mobile-big:w-12 cursor-pointer'
            alt=''
            onClick={() => {
              navigate('/');
            }}
          />
          <div className='img-wrapper hidden max-[750px]:block'>
            <img
              src={isMenu ? Close : MenuIcon}
              className='w-6 cursor-pointer'
              alt=''
              onClick={() => {
                if (isMenu) {
                  turnOffMenu();
                } else {
                  turnOnMenu();
                }
              }}
            />
          </div>
          <div className='flex items-center gap-10 max-[768px]:hidden'>
            <div
              className='font-semibold text-[18px] cursor-pointer hover:opacity-85 transition-all'
              onClick={() => {
                navigate('/ido');
              }}
            >
              IDO Listing
            </div>
            <div
              className='font-semibold text-[18px] cursor-pointer hover:opacity-85 transition-all'
              onClick={() => {
                navigate('/claim');
              }}
            >
              Claim
            </div>
            {token && account ? (
              <div className='relative px-6 py-2 text-center rounded-3xl flex justify-center items-center text-[#FFFFFF] font-bold text-[16px] leading-[28px] cursor-default address-wrapper border border-[#FFFFFF]'>
                {addressSplit(account || '')}
                <img src={ArrowDown} className='w-4' alt='' />
                <div
                  className={`px-5 flex flex-col justify-center items-center gap-4 w-[100%] bg-[#070A33] rounded-[24px] h-[60px] absolute right-0  bottom-[-63px] address-logout transition-all invisible opacity-0`}
                >
                  <div
                    className='w-full flex items-center justify-between h-[30px] cursor-pointer px-4'
                    onClick={async () => {
                      navigate('/profile');
                    }}
                  >
                    <span className='font-bold'>Profile</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className='w-[194px]'>
                <Button
                  className='primary-button relative'
                  onClick={() => {
                    if (!account) {
                      return;
                    }
                    if (!token && !!account) {
                      onSigninWallet();
                      return;
                    } else {
                      navigate('/profile');
                    }
                  }}
                >
                  {account ? (
                    'Sign'
                  ) : (
                    <div>
                      <span>Connect Wallet</span>
                      <ConnectWallet className='!opacity-0 !w-full  !h-full !absolute !top-0 !left-0'></ConnectWallet>
                    </div>
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export const Header = memo(withConnect(HeaderComponent));

export default Header;
