import React, { memo, useMemo } from 'react';
import { useAddress } from '@thirdweb-dev/react';
import withConnect from '@/hoc/with-connect';
import Logo from '@/assets/home/Logo.png';
import { LaunchPadRepository } from '@/repository/launchpad.repository';
import { useQuery } from '@tanstack/react-query';
import { ethers } from 'ethers';
import SaleImg from '@/assets/sale.png';
import USDT from '@/assets/home/usdt.png';
import { useContractProviderContext } from '@/contexts/contract-provider-context';
import { Button } from 'antd';
const ClaimHistoryComponent = ({
  token,
  connectedAccount,
  item,
  currentLaunchPad,
  handleClaimVesting,
  isLoading,
  refetchData
}: any) => {
  const address = useAddress();

  const userAccount = useMemo(() => {
    return connectedAccount || address || '';
  }, [connectedAccount, address]);

  const { data: claimHistory } = useQuery({
    queryKey: ['retrieveClaimHistory', currentLaunchPad?.address, token, connectedAccount, address],
    queryFn: () => LaunchPadRepository.getVestingClaim(connectedAccount || address || '', currentLaunchPad?.address),
    retry: false,
    enabled: !!currentLaunchPad?.address && !!address,
    refetchInterval: 50000
  });

  const { provider } = useContractProviderContext();

  const {
    data: isUsed,
    isLoading: isLoadingUsed,
    refetch
  } = useQuery({
    queryKey: ['getIsQueryUsed', provider?._network, !!item?.id, !!currentLaunchPad?.address],
    queryFn: () => LaunchPadRepository.isQueryClaimed(provider, item?.id, currentLaunchPad?.address),
    enabled: !!provider?._network && !!item?.id && !!currentLaunchPad?.address,
    retry: false,
    refetchInterval: 10000
  });
  console.log('isUsed', isUsed, item);

  console.log('claimHistory', claimHistory);

  return (
    <>
      <div className='flex items-center gap-2 flex-[0.25]'>
        <img src={SaleImg} className='w-10' alt='' />
        <div>
          <div className='font-semibold text-sm'>HyperLaunch</div>
          <div className='flex items-center gap-1'>
            <img src={Logo} className='w-6' alt='' />
            <div className='text-[#948E9C] font-semibold text-sm'>$HYPE</div>
          </div>
        </div>
      </div>
      <div className='flex-[0.25] flex flex-col items-center gap-2 justify-center'>
        <div className='flex items-center gap-2 justify-center'>
          <div className='font-semibold text-sm'>
            {' '}
            {Number(ethers.utils.formatEther(item?.pool_token_amount || '0'))?.toLocaleString('en', {
              maximumFractionDigits: 5,
              minimumFractionDigits: 0
            })}{' '}
            HYPE{' '}
          </div>
          <img src={Logo} className='w-6' alt='' />
        </div>
        <div className='flex items-center gap-2 justify-center'>
          <div className='font-semibold text-sm'>
            {' '}
            {Number(ethers.utils.formatEther(item?.sale_token_amount || '0'))?.toLocaleString('en', {
              maximumFractionDigits: 5,
              minimumFractionDigits: 0
            })}{' '}
            USDT{' '}
          </div>
          <img src={USDT} className='w-6' alt='' />
        </div>
      </div>
      <div className='font-normal text-sm flex-[0.25] text-center'>
        {new Date(item?.time_issued)?.toLocaleDateString('en-US')}
      </div>
      <div className='flex-[0.25] flex items-center justify-end text-end'>
        {isLoadingUsed ? (
          '...'
        ) : isUsed?.isUsed ? (
          <span className='text-green-500'>Claimed</span>
        ) : (
          <Button
            className='primary-button !w-[120px] !h-[40px]'
            loading={isLoading}
            onClick={async () => {
              const rawData = {
                id: item?.id,
                buyer: item?.buyer,
                pool_token_amount: item?.pool_token_amount,
                sale_token_amount: item?.sale_token_amount,
                signature: item?.signature
              };
              await handleClaimVesting(rawData);
              setTimeout(() => {
                refetch();
                refetchData();
              }, 10000);
            }}
          >
            Retry
          </Button>
        )}
      </div>
    </>
  );
};
export const ClaimHistory = memo(withConnect(ClaimHistoryComponent));
