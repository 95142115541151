import React, { memo, useMemo } from 'react';
import { Wrapper } from './claim.styled';
import Header from '@/components/header/header';
import { ConnectWallet, useAddress } from '@thirdweb-dev/react';
import withConnect from '@/hoc/with-connect';
import { Button } from 'antd';
import Logo from '@/assets/home/Logo.png';
import { LaunchPadRepository } from '@/repository/launchpad.repository';
import { useQuery } from '@tanstack/react-query';
import { lowerCacheOptions } from '@/constants/app.constant';
import { ethers } from 'ethers';
import { useCountDown } from '@/hooks/use-count-down';
import { useClaimVesting } from '../profile/hooks/use-claim-vesting';
import Banner from '@/assets/home/banner_dark.png';
import { addressSplit } from '@/utilities/string.utils';
import { ClaimHistory } from './claim-history';
import USDT from '@/assets/home/usdt.png';
const ClaimPageComponent = ({ token, account, onSigninWallet, connectedAccount }: any) => {
  const address = useAddress();
  const { data: allLaunchpad } = useQuery({
    queryKey: ['retrieveAllLaunchpad', token],
    queryFn: () => LaunchPadRepository.getAllLaunchpad(),
    retry: false,
    enabled: !!token,
    ...lowerCacheOptions,
    refetchOnMount: 'always',
    refetchInterval: 60000
  });
  const currentLaunchPad = useMemo(() => {
    return allLaunchpad?.[0];
  }, [allLaunchpad]);

  const userAccount = useMemo(() => {
    return connectedAccount || address || '';
  }, [connectedAccount, address]);

  console.log('currentLaunchPad', currentLaunchPad);
  const { data: allVestingClaim, refetch: refetchAllVesting } = useQuery({
    queryKey: ['retrieveAllVestingClaim', userAccount, currentLaunchPad?.address],
    queryFn: () => LaunchPadRepository.getVestingClaim(userAccount, currentLaunchPad?.address),
    retry: false,
    enabled: !!currentLaunchPad?.address && !!userAccount,
    ...lowerCacheOptions,
    refetchOnMount: 'always',
    refetchInterval: 60000
  });

  const { data: estimateVestingClaim, refetch: refetchNextClaim } = useQuery({
    queryKey: ['retrieveEstimateVestingClaim', userAccount, currentLaunchPad?.address],
    queryFn: () =>
      LaunchPadRepository.estimateNextVestingClaim(connectedAccount || address || '', currentLaunchPad?.address),
    retry: false,
    enabled: !!currentLaunchPad?.address && !!userAccount,
    ...lowerCacheOptions,
    refetchOnMount: 'always',
    refetchInterval: 60000
  });

  const { data: claimHistory, refetch: refetchClaimHistory } = useQuery({
    queryKey: ['retrieveClaimHistory', currentLaunchPad?.address, token, connectedAccount, address],
    queryFn: () => LaunchPadRepository.getVestingClaim(connectedAccount || address || '', currentLaunchPad?.address),
    retry: false,
    enabled: !!currentLaunchPad?.address && !!address,
    refetchInterval: 50000
  });

  console.log('claimHistory', claimHistory);

  const { handleClaimVesting, isLoading } = useClaimVesting({
    launchpad: currentLaunchPad,
    onSuccess: () => {},
    onFailed: () => {}
  });

  console.log('allVestingClaim', allVestingClaim, estimateVestingClaim);

  const isValidAmount = useMemo(() => {
    return estimateVestingClaim?.pool_token_amount === '0' && estimateVestingClaim?.sale_token_amount === '0';
  }, [estimateVestingClaim]);

  const {
    timerDays: endDays,
    timerHours: endHours,
    timerMinutes: endMinutes,
    timerSeconds: endSeconds,
    countdownDone: isEnd
  } = useCountDown(isValidAmount ? new Date(estimateVestingClaim?.next_claim_time).getTime() : 0, '3');

  // const { data: userBuyActivities } = useQuery({
  //   queryKey: ['retrieveUserBuyActivities', currentLaunchPad?.address],
  //   queryFn: () => LaunchPadRepository.getUserBuyActivities(currentLaunchPad?.address),
  //   retry: false,
  //   enabled: !!currentLaunchPad?.address,
  //   refetchInterval: 10000
  // });
  const isMobile = window.innerWidth <= 768;
  return (
    <Wrapper>
      <Header />
      <img src={Banner} className='absolute top-0 left-0 w-full z-[1]' alt='' />

      <div className='relative z-[2] flex items-center gap-[64px] max-[990px]:gap-[36px] mt-[152px] w-full max-w-[1136px] px-4 flex-col'>
        <div className='font-bold text-center text-[72px] max-mobile-xl:text-[56px] max-mobile:text-[48px] max-mobile-sm:text-[40px]  max-w-[560px] w-full'>
          Claim
        </div>
        <div className='flex items-start gap-4 w-full max-mobile:w-full bg-[#070A33] rounded-2xl p-6 overflow-auto max-h-[800px] max-[900px]:flex-col'>
          <div>
            <img src={Logo} className='w-[120px]' alt='' />
          </div>
          <div className='w-full '>
            <div className='font-bold text-[32px]'>HyperLaunch</div>
            <div className='font-medium text-[#948E9C] mt-1'>{addressSplit(userAccount)}</div>
            <div className='w-full h-[1px] bg-[#42467D] my-6'></div>
            <div className='grid grid-cols-2 gap-y-8'>
              <div className='flex flex-col items-start gap-2'>
                <div className='text-sm text-[#948E9C] font-medium'>Total Token</div>
                <div className='flex items-center gap-2'>
                  <img src={Logo} className='w-5' alt='' />
                  <div className='text-sm font-bold'>
                    {' '}
                    {Number(ethers.utils.formatEther(estimateVestingClaim?.total_amount || '0'))?.toLocaleString('en', {
                      maximumFractionDigits: 5,
                      minimumFractionDigits: 0
                    })}{' '}
                    $HYPE
                  </div>
                </div>
              </div>
              <div className='flex flex-col items-start gap-2'>
                <div className='text-sm text-[#948E9C] font-medium'>Claimed Amount</div>
                <div className='flex items-center gap-2'>
                  <img src={Logo} className='w-5' alt='' />
                  <div className='text-sm font-bold'>
                    {Number(ethers.utils.formatEther(estimateVestingClaim?.claimed_amount || '0'))?.toLocaleString(
                      'en',
                      {
                        maximumFractionDigits: 5,
                        minimumFractionDigits: 0
                      }
                    )}{' '}
                    $HYPE
                  </div>
                </div>
              </div>
              <div className='flex flex-col items-start gap-2'>
                <div className='text-sm text-[#948E9C] font-medium'> Vesting Amount</div>
                <div className='flex items-center gap-2'>
                  <img src={Logo} className='w-5' alt='' />
                  <div className='text-sm font-bold'>
                    {' '}
                    {(
                      Number(ethers.utils.formatEther(estimateVestingClaim?.total_amount || '0')) -
                      Number(ethers.utils.formatEther(estimateVestingClaim?.claimed_amount || '0'))
                    )?.toLocaleString('en', {
                      maximumFractionDigits: 5,
                      minimumFractionDigits: 0
                    })}{' '}
                    $HYPE
                  </div>
                </div>
              </div>
              <div className='flex flex-col items-start gap-2'>
                <div className='text-sm text-[#948E9C] font-medium'>Vesting time</div>
                <div className='flex items-center gap-2'>
                  <div className='text-sm font-bold'>{estimateVestingClaim?.vesting_time} days</div>
                </div>
              </div>
            </div>
            <div className='vesting-section flex items-center gap-3 mt-6 p-4 bg-[#141960] rounded-2xl max-[768px]:flex-col'>
              <div className='flex flex-col items-start flex-[0.45] max-[768px]:w-full'>
                <div className='font-bold text-base'>Claim Token </div>
                <div className='flex items-center gap-2 mt-3'>
                  <div className='w-[72px] h-[72px] max-[768px]:w-[56px] max-[768px]:h-[56px] rounded-2xl border-[1px] border-[#42467D] bg-[#10051C80] flex flex-col items-center justify-center max-[768px]:flex-row gap-1'>
                    <span className='font-bold text-xs'>{endDays}</span>
                    <span className='font-normal text-xs'>{isMobile ? 'd' : 'days'} </span>
                  </div>
                  <div>:</div>
                  <div className='w-[72px] h-[72px] max-[768px]:w-[56px] max-[768px]:h-[56px] rounded-2xl border-[1px] border-[#42467D] bg-[#10051C80] flex flex-col items-center justify-center max-[768px]:flex-row gap-1'>
                    <span className='font-bold text-xs'>{endHours}</span>
                    <span className='font-normal text-xs'>{isMobile ? 'h' : 'hours'}</span>
                  </div>
                  <div>:</div>
                  <div className='w-[72px] h-[72px] max-[768px]:w-[56px] max-[768px]:h-[56px] rounded-2xl border-[1px] border-[#42467D] bg-[#10051C80] flex flex-col items-center justify-center max-[768px]:flex-row gap-1'>
                    <span className='font-bold text-xs'>{endMinutes}</span>
                    <span className='font-normal text-xs'> {isMobile ? 'm' : 'minutes'} </span>
                  </div>
                  <div>:</div>
                  <div className='w-[72px] h-[72px] max-[768px]:w-[56px] max-[768px]:h-[56px] rounded-2xl border-[1px] border-[#42467D] bg-[#10051C80] flex flex-col items-center justify-center max-[768px]:flex-row gap-1'>
                    <span className='font-bold text-xs'>{endSeconds}</span>
                    <span className='font-normal text-xs'>{isMobile ? 's' : 'seconds'} </span>
                  </div>
                </div>
              </div>
              <div className='flex-[0.1] flex items-center justify-center max-[768px]:hidden'>
                <div className='h-[80px] w-[1px] bg-[#42467D]'></div>
              </div>
              <div className='flex flex-col flex-[0.45] max-[768px]:w-full'>
                {!isValidAmount ? (
                  <>
                    <div className='text-sm text-[#948E9C] font-medium'>Claimable amount</div>
                    <div className='flex items-center gap-2 mt-3'>
                      <img src={Logo} className='w-5' alt='' />
                      <div className='text-sm font-bold'>
                        {Number(
                          ethers.utils.formatEther(estimateVestingClaim?.pool_token_amount || '0')
                        )?.toLocaleString('en', {
                          maximumFractionDigits: 5,
                          minimumFractionDigits: 0
                        })}{' '}
                        $HYPE
                      </div>
                    </div>
                    <div className='flex items-center gap-2 mt-2'>
                      <img src={USDT} className='w-5' alt='' />
                      <div className='text-sm font-bold'>
                        {Number(
                          ethers.utils.formatEther(estimateVestingClaim?.sale_token_amount || '0')
                        )?.toLocaleString('en', {
                          maximumFractionDigits: 5,
                          minimumFractionDigits: 0
                        })}{' '}
                        $USDT
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='flex items-start gap-2 flex-col '>
                    <div className='text-sm text-[#948E9C] font-medium'>Next claimable amount</div>
                    <div className='flex items-center gap-2'>
                      <img src={Logo} className='w-5' alt='' />
                      <div className='text-sm font-bold'>
                        {Number(
                          ethers.utils.formatEther(estimateVestingClaim?.next_claim_amount || '0')
                        )?.toLocaleString('en', {
                          maximumFractionDigits: 5,
                          minimumFractionDigits: 0
                        })}{' '}
                        $HYPE
                      </div>
                    </div>
                  </div>
                )}
                <div className='w-full mt-4'>
                  <Button
                    className='primary-button max-[768px]:w-full'
                    onClick={async () => {
                      if (!token && !!account) {
                        onSigninWallet();
                        return;
                      }
                      await handleClaimVesting();
                      setTimeout(() => {
                        refetchAllVesting();
                        refetchClaimHistory();
                        refetchNextClaim();
                      }, 5000);
                    }}
                    loading={isLoading}
                    disabled={!isEnd}
                  >
                    {account ? (
                      token ? (
                        'Claim'
                      ) : (
                        'Sign'
                      )
                    ) : (
                      <div>
                        <span>Connect Wallet</span>
                        <ConnectWallet className='!opacity-0 !w-full  !h-full !absolute !top-0 !left-0'></ConnectWallet>
                      </div>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='font-bold text-[40px]  max-mobile:text-[32px]'>Claim history</div>
        <div className='w-full bg-[#070A33] rounded-2xl p-6 overflow-auto max-h-[800px]'>
          {claimHistory?.items?.length > 0 ? (
            <div className=' flex flex-col items-center gap-4 max-mobile:min-w-[650px]'>
              <div className='w-full flex items-center gap-6'>
                <div className='font-normal text-sm text-[#999999] flex-[0.25] text-start'>IDO Sales</div>

                <div className='font-normal text-sm text-[#999999] flex-[0.25] text-center'>Token claim</div>
                <div className='font-normal text-sm text-[#999999] flex-[0.25] text-center'>Date</div>
                <div className='font-normal text-sm text-[#999999] flex-[0.25] text-end'>Status</div>
              </div>
              <div className='w-full h-[1px] bg-[#42467D]'></div>
              {claimHistory?.items?.map((item: any, key: number) => {
                return (
                  <div key={key} className='w-full flex items-center gap-6 '>
                    <ClaimHistory
                      item={item}
                      currentLaunchPad={currentLaunchPad}
                      handleClaimVesting={handleClaimVesting}
                      isLoading={isLoading}
                      refetchData={() => {
                        setTimeout(() => {
                          refetchAllVesting();
                          refetchClaimHistory();
                          refetchNextClaim();
                        }, 5000);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className='w-full px-2 py-4 text-center flex items-center justify-center font-bold text-sm'>
              NO HISTORY
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};
export const ClaimPage = memo(withConnect(ClaimPageComponent));
