import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: absolute;
  z-index: 100;
  background: #000321;
  backdrop-filter: blur(20px);
  top: 0;
  .address-wrapper {
    &:hover {
      .address-logout {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .animate-slideDown {
    animation: slideDown 1s ease-out forwards;
  }
  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;
