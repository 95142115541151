import { useChainId, useNetwork, useSDK, useBalance, useAddress } from '@thirdweb-dev/react';
import { useCallback, useState } from 'react';
import { ENVS } from '@/config/env.config';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
interface IPropsApproveToken {
  tokenAmount: any;
  spender: string;
  saleToken: string;
  onSuccess: () => void;
  onFailed: () => void;
}
export const useApproveToken = (props: IPropsApproveToken) => {
  const { tokenAmount, spender, onSuccess, saleToken } = props;
  const { data: accountBalance } = useBalance();
  const { data: accountUSDTBalance } = useBalance(saleToken);
  const sdk = useSDK();

  console.log('spender', spender);
  const chainId = useChainId();
  const [, switchNetwork] = useNetwork();
  const [isLoading, setIsLoading] = useState(false);
  const address = useAddress();
  const handleApprove = useCallback(async () => {
    setIsLoading(true);
    if (!tokenAmount || Number(tokenAmount || '0') === 0) {
      toast.dismiss();
      setIsLoading(false);
      toast.error('Invalid amount');
      throw new Error('Invalid amount');
    }
    const baseChainId = parseInt(ENVS.VITE_BASE_CHAIN_ID?.[0] || '1');
    if (chainId !== baseChainId) {
      toast.dismiss();
      toast.error(`Network mismatched: ${chainId} -> ${baseChainId}`);
      switchNetwork && switchNetwork(baseChainId);
      setIsLoading(false);
      throw new Error('Network mismatched');
    }
    if (accountBalance?.value?.lt(ethers.utils.parseEther('0.01'))) {
      toast.dismiss();
      setIsLoading(false);
      toast.error('Insufficient LUMIA amount for fees!');
      throw new Error('Insufficient LUMIA amount for fees!');
    } else {
      if (accountUSDTBalance?.value?.lt(tokenAmount)) {
        toast.dismiss();
        setIsLoading(false);
        toast.error(`Insufficient ${accountUSDTBalance?.symbol || 'token'} amount!`);
        throw new Error(`Insufficient ${accountUSDTBalance?.symbol || 'token'} amount!`);
      } else {
        console.log('spender', spender);
        const iface = new ethers.utils.Interface(['function approve(address spender,uint256 amount)']);
        const _data = [spender, tokenAmount];
        console.log('_data', _data);
        const data = iface.encodeFunctionData('approve', _data);

        const rawTransaction = {
          from: address,
          to: saleToken,
          value: 0,
          data: data,
          chainId: Number(ENVS.VITE_BASE_CHAIN_ID?.[0]),
          type: 0
        };
        console.log('rawTransaction', rawTransaction);
        try {
          const tx = await sdk?.wallet?.sendRawTransaction(rawTransaction);
          let receipt = await tx?.wait();
          if (receipt) {
            onSuccess();
          }

          setIsLoading(false);
        } catch (error) {
          console.log('err', error);
          setIsLoading(false);
          toast.dismiss();
          toast.error(error as string);
        }
      }
    }
  }, [
    accountUSDTBalance,
    accountBalance,
    chainId,
    sdk,
    onSuccess,
    switchNetwork,
    tokenAmount,
    spender,
    address,
    saleToken
  ]);
  return {
    handleApprove,
    isLoading
  };
};
