import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  height: fit-content;
  width: 100vw;
  height: 100svh;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000321;
`;
